import React from 'react';
import "../utils/styles/Route.scss";

function ErrorPage() : React.ReactElement {
  return (
    <div className='content'>
      <p>Oh no! There seems to be a problem here!</p>
    </div>
  );
}

export default ErrorPage;