import React from "react";

import "../utils/styles/Route.scss";
import "../utils/styles/Achievements.scss";

import carleton from "../utils/images/carleton-H-300.svg";
import coursera from "../utils/images/coursera-logo-full-rgb.svg";


export function Achievements() : React.ReactElement {
    const iconDefintion = (status: string) => {
        if (status === "Completed") {
            return <i className="fa-solid fa-check" style={{color: 'green'}}></i>
        }
        else if (status === 'Started') {
            return <i className="fa-regular fa-hourglass" style={{color: 'blue'}}></i>
        }
        else {
            return null
        }
    }

    return (
        <div className="content">
            <div id="achievement-container">
                <div className="achievement">
                    <img src={carleton} alt="Carleton University Logo" width={400}/>
                    <div>
                        <h2><a href="https://calendar.carleton.ca/undergrad/undergradprograms/computerscience/#Computer_Science__BCS_Honours" target="_blank" rel="noreferrer">Bachelor's Degree in Computer Science <i className="fa-solid fa-link"></i></a></h2>
                        <ul>
                            <li><a href="https://carleton.ca/provost/2023/05/provost-scholar-award-recipients-3/#sebastian_navas_chaparro_" target="_blank" rel="noreferrer">Provost Scholar Award (2023) <i className="fa-solid fa-link"></i></a></li>
                            <li>GPA: 11.5 / 12.0 </li>
                            <li>Graduated with High Distinction</li>
                            <li>Undergraduate Student Research Award (2020 -- 2022)</li>
                            <li>I-CUREUS (2020, 2021)</li>
                            <li>CS Team Lead and Peer Mentor at Science Student Success Centre</li>
                        </ul>
                    </div>
                </div>
                <hr style={{borderTop: 'dotted', borderBottom: 'none'}}></hr>
                <div className="achievement">
                    <img src={coursera} alt="Coursera Logo" width={400}/>
                    <div>
                        <h2><a href="https://www.coursera.org/professional-certificates/ibm-data-engineer" target="_blank" rel="noreferrer">IBM Data Engineering Professional Certificate <i className="fa-solid fa-link"></i></a></h2>
                        <ul>
                            <li>Introduction to Data Engineering {iconDefintion('Completed')}</li>
                            <li>Python for Data Science, AI & Development {iconDefintion('Completed')}</li>
                            <li>Python Project for Data Engineering {iconDefintion('Completed')}</li>
                            <li>Introduction to Relational Databases (RDBMS) {iconDefintion('Completed')}</li>
                            <li>Databases and SQL for Data Science with Python {iconDefintion('Completed')}</li>
                            <li>Hands-on Introduction to Linux Commands and Shell Scripting {iconDefintion('Started')}</li>
                            {/* <li>Relational Database Administration (DBA) {iconDefintion('Not Started')}</li>
                            <li>ETL and Data Pipelines with Shell, Airflow and Kafka {iconDefintion('Not Started')}</li>
                            <li>Getting Started with Data Warehousing and BI Analytics {iconDefintion('Not Started')}</li>
                            <li>Introduction to NoSQL Databases {iconDefintion('Not Started')}</li>
                            <li>Introduction to Big Data with Spark and Hadoop {iconDefintion('Not Started')}</li>
                            <li>Machine Learning with Apache Spark {iconDefintion('Not Started')}</li>
                            <li>Data Engineering Capstone Project {iconDefintion('Not Started')}</li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}