import React, {useEffect, useState} from 'react';
import '../styles/Typewriter.scss';

interface ITypewriter {
    titles : string[]
}

function Typewriter({ titles } : ITypewriter) : React.ReactElement {
    const [displayedTitle, setDisplayedTitle] = useState<string>("");

    useEffect( () => {
        /**
         * This function is responsible for inputing the titles into the displayedTitle state variable, 
         * making it appear like the title in the scree is being typed
         */
        (async function type() {
            let i, j;

            while (true) {
                for (i = 0; i < titles.length; ++i) {
                    for (j = 0; j < titles[i].length; ++j) {
                        if (await sleep(100)) {
                            setDisplayedTitle(titles[i].slice(0, j + 1));
                        }
                    }

                    await sleep(1000);
        
                    for (j = titles[i].length; j >= 0; --j) {
                        if (await sleep(100)) {
                            setDisplayedTitle(titles[i].slice(0, j));
                        }
                    }
                }
            }
        })();
    }, [titles]);

    return (
        <><span>{displayedTitle}</span><span id="cursorSymbol">|</span></>
    );
}

/**
     * This function stops execution for the specified time in miliseconds
     * @param miliseconds 
     */
function sleep(miliseconds : number) : Promise<Boolean> {
    return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, miliseconds);
    });
}


export default Typewriter;