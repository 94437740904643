import React, {useRef} from 'react';
import { NavLink } from 'react-router-dom';
import '../utils/styles/Home.scss';
import Typewriter from '../utils/components/Typewriter';
import { titles } from '../utils/types/Experience';
import Skills from '../utils/components/Skills';
import { projectHighlights } from '../utils/data/Projects';
import Card from '../utils/components/Card';

function Home() : React.ReactElement {
  const titles_ref = useRef<string[]>(titles);
  const project : number = Math.floor(Math.random() * projectHighlights.length);

  return (
    <div className = "content">
      <div className='about'>
        <div id='left'>
            <div id='intro'>
              <h1>I am Sebastian, </h1>
              <h1><Typewriter titles={titles_ref.current} /></h1>
            </div>
            <h2 id='punchline'>I am passionate about learning new things and solving problems with technology.</h2>
            <div id='highlights'>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={2}><h1>Project Highlight</h1></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr id='big'>
                      <td>
                          <h3>{projectHighlights[project].name}</h3>
                          <img src={require(`../utils/icons/${projectHighlights[project].photo}`)} alt={projectHighlights[project].photo} width={'300px'}/>
                          <p>{projectHighlights[project].summary}</p>
                      </td>
                      <td>
                          <h3>Skills</h3>
                          <Skills skills={projectHighlights[project].skills}/>
                      </td>
                    </tr>
                    <tr id='small'>
                      <td>
                        <Card
                          name={projectHighlights[project].name} 
                          summary={projectHighlights[project].summary} 
                          company={projectHighlights[project].company} 
                          context={projectHighlights[project].context}
                          skills={projectHighlights[project].skills}
                          photo={projectHighlights[project].photo}
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>
                        <div id="navigation">
                          <NavLink to="/projects"><button>See More...</button></NavLink>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Home;