import React, { useState } from 'react';
import "../utils/styles/Route.scss";
import "../utils/styles/Contact.scss";

import linkedin from "../utils/images/In-Blue-128@2x.png";
import github from "../utils/images/github-mark.svg";

function Contact() : React.ReactElement {
  const[sent, setSent] = useState<boolean | null>(null);

  // FormEvent<HTMLFormElement>
  const handleSubmit = (event : any) => {
    event.preventDefault();
    sendEmail({
      'sender': event.target[0].value,
      'subject': event.target[1].value,
      'body': event.target[2].value
    })
    .then(() => {console.log('success'); setSent(true)})
    .catch((err) => {console.log(err); setSent(false)});
  };

  const sendEmail = (data : Record<string, string>) => {
    return fetch("https://api.sebastiannavas.ca/email", {
      method: 'POST',
      headers: {
        "Content-Type": "text/plain"
      },
      body: JSON.stringify(data)
    })
  }

  return (
    <div className='content'>
      <div id='contact'>
        <div id='links'>
          {/* <h1>Links:</h1> */}
          <div>
            <a href='https://www.linkedin.com/in/sebastiannavasch/' target="_blank" rel="noreferrer"><img src={linkedin} alt='LinkedIn Logo' width={75}/></a>
          </div>
          <div>
            <a href='https://github.com/SebastianN3' target="_blank" rel="noreferrer"><img src={github} alt='Github Logo' width={75}/></a>
          </div>
          <div>
            <a href='https://chorus.scs.carleton.ca/people/sebastian-navas-chaparro/' target="_blank" rel="noreferrer"><img src={'https://chorus.scs.carleton.ca/wp-content/uploads/2014/08/LogoClean1.png'} alt="Carleton's Human-Oriented Research in Usable Security Lab Logo" width={75}/></a>
          </div>
        </div>
        
        <div id='form'>
          {sent === false ? <div className='message'>There was an error. Please use the email in my resume to contact me. I apologize for the inconvenience.</div> : null}
          {(sent === null || sent === false) ?
            (
              <form id="contact-form" onSubmit={handleSubmit}>
                <div className='field'>
                  <label>From:</label>
                  <input className='input' type="email"  name="sender" id="sender" required />
                </div>
                <hr />
                {/* <div className='field'>
                  <label>To:</label>
                  <input className='input' type="email"  name="receiver" id="receiver" value="email@example.com" readOnly/>
                </div>
                <hr /> */}
                <div className='field'>
                  <label>Subject:</label>
                  <input className='input' type="text"  name="subject" id="subject"/>
                </div>
                <hr />
                <div className='field'>
                  {/* <label>Body:</label> */}
                  <textarea className='input' name="body" id="body" placeholder='Body...' rows={14} required/>
                </div>
                <hr />
                <div className='field'>
                  {sent === false ?
                    null
                    :
                    (<input className='input' type='submit' value="Send"/>)
                  }
                </div>
              </form>
            ) 
              :
            <div className='message'>Thank you!</div>
          }
        </div>
      </div>
    </div>
  );
}

export default Contact;