import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import '../styles/Navigation.scss';

function Navigation() : React.ReactElement {
    const [dropdown, setDropdown] = useState<boolean>();

    return (
        <>
        <div id="dropdown-menu">
            <button id="menu-button" onClick={() => setDropdown(!dropdown)}><i className="fa-solid fa-bars"></i></button>
            <div className="dropdown-content" style={{display: dropdown ? 'block' : 'none'}}>
                <NavLink to="/" onClick={() => setDropdown(!dropdown)}>Home</NavLink>
                <NavLink to="/projects" onClick={() => setDropdown(!dropdown)}>Projects</NavLink>
                <NavLink to="/achievements" onClick={() => setDropdown(!dropdown)}>Achievements</NavLink>
                <NavLink to="/resume" onClick={() => setDropdown(!dropdown)}>Resume</NavLink>
                <NavLink to="/contact" onClick={() => setDropdown(!dropdown)}>Contact</NavLink>
            </div>
        </div>

        <nav id="open-menu">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/projects">Projects</NavLink>
            <NavLink to="/achievements">Achievements</NavLink>
            <NavLink to="/resume">Resume</NavLink>
            <NavLink to="/contact">Contact</NavLink>
        </nav>
        </>
    );
  }
  
  export default Navigation;