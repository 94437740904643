import React from 'react';
import "../styles/Skills.scss";
import { skill_colours } from '../data/Skills';

interface ISkills {
  skills : Set<String>
}

function Skills({skills} : ISkills) : React.ReactElement {
    return (
        <div className="skillsContainer">
          {Array.from(skills).map((element, index) => (
            <div key={`${element}_${index}`} style={{backgroundColor : skill_colours.get(element)}} className="skill">
              <p style={{color: '#FFFFFF'}}>{element}</p>
            </div>
          ))}
        </div>
    );
  }
  
export default Skills;