import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter,
         RouterProvider,
         Outlet } from "react-router-dom";

import './utils/styles/index.scss';

import Header from "./utils/components/Header";
import Home from "./routes/Home";
import Projects from "./routes/Projects";
import Resume from "./routes/Resume";
import Contact from "./routes/Contact";
import ErrorPage from "./routes/ErrorPage";
import reportWebVitals from './reportWebVitals';
import { Achievements } from "./routes/Achievements";

const router = createBrowserRouter([
  {
    path: "/",
    element: <WrapperElement />,
    errorElement: <ErrorWrapperElement />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/home",
        element: <Home />
      },
      {
        path: "/achievements",
        element: <Achievements />
      },
      {
        path: "/projects",
        element: <Projects />
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/resume",
        element: <Resume />
      }
    ]
  },
]);

function WrapperElement() : React.ReactElement {
  return(
    <>
      <Header />
      <Outlet />
    </>
  );
}

function ErrorWrapperElement() : React.ReactElement {
  return(
    <>
      <Header/>
      <ErrorPage />
    </>
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
