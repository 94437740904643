import React from 'react';
import "../styles/Card.scss";
import Skills from './Skills';
import { IProject } from '../types/Projects';

function Card(item : IProject) : React.ReactElement {
    return (
        <div className='card'>
            <h4>
                {item.link !== undefined?
                    (
                        <a href={item.link} 
                        target="_blank" 
                        rel="noreferrer">
                            {item.name} <i className="fa-solid fa-link"></i>
                        </a>
                    )
                        :
                    (item.name)
                }
            </h4>
            {item.photo !== undefined ?
                (<img src={require(`../icons/${item.photo}`)} width={item.photo.endsWith('svg') ? '250px' : '350px'} alt='icon'/>)
                    :
                null
            }
            <div className='description'>
                {item.company !== undefined ?
                    (<div id='company'>{item.company}</div>) 
                    : 
                    null
                }
                <div>{item.summary}</div>
            </div>
            <Skills skills={item.skills} />
        </div>
    );
  }
  
  export default Card;