import React, { useRef } from 'react';
import '../styles/Header.scss';
import Typewriter from './Typewriter';
import Navigation from './Navigation';
import { titles } from '../types/Experience';
import { useLocation } from 'react-router-dom';

function Header() : React.ReactElement {
    const titles_ref = useRef<string[]>(titles);
    let location = useLocation();

    return (
        <div id='container'>
            <div id="header">
                <div className='name'>
                    {location.pathname === '/' || location.pathname === '/home'?  
                        (<div>Sebastian Navas Chaparro </div>)
                        :
                        (<><div>Sebastian Navas Chaparro: </div><div><Typewriter titles={titles_ref.current} /></div></>)
                    }
                </div>
                <Navigation />
            </div>
            <hr></hr>
        </div>
    );
  }
  
  export default Header;