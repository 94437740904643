import { useCallback, useState } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';

import '../utils/styles/Resume.scss';
import "../utils/styles/Route.scss";

export default function Resume() : React.ReactElement {
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width - (entry.contentRect.width * 0.02));
    }
  }, []);

  useResizeObserver(containerRef, {}, onResize);

  return (
    <div ref={setContainerRef}>
        <iframe src={'https://resume.sebastiannavas.ca'} title='resume' width={containerWidth}/>
    </div>
  );
}