import { IProject, Context, ProjectContextOption } from "../types/Projects";

export const projectData : IProject[] = [
    {
        name: 'Personal Website',
        summary: "This website serves two purposes: presenting information about me and giving me an excuse to play around with new tools. In making this website, I have learned and used GitHub Actions and an array of AWS services, including Amplify, S3, CloudFront, and API Gateway.",
        context: Context.PERSONAL,
        skills: new Set(['AWS', 'TypeScript', 'React', 'SASS', 'GitHub Actions']),
        photo: 'cloud.svg',
        link: 'https://www.sebastiannavas.ca'
    },
    {
        name: 'Password Constellation',
        summary: "Password constellation has the goal of helping users identify the effects of password reuse on the security of all their credentials. It measures relatedness between passwords using the Levenshtein distance to compute a network visualization.",
        context: Context.PERSONAL,
        skills: new Set(['TypeScript', 'React', 'D3', 'Graphs', 'Louvain Cluster', 'SASS', 'LaTeX']),
        photo: 'password-constellation.png',
        link: 'https://report-hub.scs.carleton.ca/projectPage/1481/'
    },
    {
        name: 'NoViz: Non-Visual Security Notifications (Thermal)',
        summary: "NoViZ-T is an internet-connected device that creates heat cues to represent security notifications. This system is independent of notification logic.",
        context: Context.WORK,
        company: "Carleton's Human-Oriented Research in Usable Security Lab",
        skills: new Set(['Arduino', 'JavaScript', 'MongoDB', 'Node', 'Express', 'LaTeX', 'Markdown']),
        link: 'https://github.com/choruslab/NoVizThermal',
        photo: 'noviz-t.jpg'
    },
    {
        name: 'Excel Data Scanner',
        summary: "I developed and tested a module leveraging the Open XML SDK to enable a data discovery and tagging tool to analyze Microsoft Excel tabular data. I followed Behavior-Driven Development (BDD) to meet the requirements set by the team.",
        context: Context.WORK,
        company: "Apption",
        skills: new Set(['C#', '.NET', 'SpecFlow', 'Agile']),
        photo: 'spreadsheet.svg'
    },
    {
        name: "Security Information and Event Management System (SIEM)",
        summary: "This project involved developing and testing several UI components following designs from the UX team. Additionally, I implemented resolvers for an Apollo Server to enable data queries using GraphQL from the front end.",
        context: Context.WORK,
        company: "2Keys — An Interac Company",
        skills: new Set(['TypeScript', 'React', 'Jest', 'GraphQL', 'Node', 'SASS', 'LaTeX']),
        photo: 'frontend.svg'
    },
    {
        name: 'System Administration',
        summary: "This role involved supporting web development projects. Tasks included updating software, responding to security vulnerabilities, configuring backup mechanisms and changing TLS certificates. ",
        context: Context.WORK,
        company: "Carleton's Human-Oriented Research in Usable Security Lab",
        skills: new Set(['OpenStack', 'Ubuntu Linux', 'WordPress', 'Apache', 'MySQL']),
        photo: 'servers.svg'
    },
    {
        name: 'NoViz: Non-Visual Security Notifications (Smell)',
        summary: "NoViZ-S is a device that creates smell cues to represent security notifications. This system leverages the Have I been P@wned database to warn about websites with previous data breaches.",
        context: Context.WORK,
        company: "Carleton's Human-Oriented Research in Usable Security Lab",
        skills: new Set(['Arduino', 'Node', 'Express', 'REST', 'JavaScript', 'LaTeX']),
        link: 'https://github.com/choruslab/NoVizSmell',
        photo: 'noviz-s.png'
    },
    {
        name: 'Equity, Diversity and Inclusion in CS',
        summary: "This project aimed at improving computer science programs to foster equity, diversity and inclusion. It involved designing and conducting interviews and a survey, analyzing qualitative and quantitative data and writing a report to share findings. ",
        context: Context.RESEARCH,
        company: "Carleton's Human-Oriented Research in Usable Security Lab",
        skills: new Set(['Interviews', "JavaScript", "Qualtrics", "LaTeX"]),
        photo: 'data-analysis.svg'
    },
    {
        name: 'User Study: FIDO vs. Phishing',
        summary: "I gathered data about the security of FIDO authentication systems by conducting a user study. This data enabled senior researchers to analyze the effectiveness of FIDO in preventing phishing attacks. ",
        context: Context.RESEARCH,
        company: "Carleton's Human-Oriented Research in Usable Security Lab",
        skills: new Set(['Interviews']),
        photo: 'interview.svg',
        link: 'https://www.usenix.org/conference/usenixsecurity21/presentation/ulqinaku'
    },
    {
        name: "Ad Practices in App Monetization",
        summary: "This project involved designing a survey to understand the experiences of novice app developers with ad-based monetization, particularly regarding user privacy and security. I analyzed the data via descriptive statistics and data visualizations.",
        context: Context.RESEARCH,
        company: "Carleton's Human-Oriented Research in Usable Security Lab",
        skills: new Set(['Qualtrics', 'Microsoft Excel']),
        photo: 'data-visualization.svg'
    },
    {
        name: 'Flamingo',
        summary: "Flamingo is a Java-based desktop app with the goal of helping users build a budget-conscious travel plan. It leverages the Google Places API to gather destinations and activities based on a set user budget.",
        context: Context.PERSONAL,
        skills: new Set(['Java', 'JavaFX', 'Google Cloud']),
        link: 'https://devpost.com/software/flamingo-j5rlqb',
        company: 'CuHacking',
        photo: 'flamingo-screen.png'
    },
    {
        name: 'Electrical Stimulation Device',
        summary: "This project leverages QT C++ to develop a desktop app that emulates OASIS pro, a cranio-electro stimulation device. My team (4 members) worked together iteratively by implementing methodologies like Agile and code reviews. ",
        context: Context.CLASS,
        skills: new Set(['C++', 'QT', 'Git', 'GitHub']),
        link: 'https://github.com/alejita11011/CES-Simulator',
        photo: 'CES.jpg'
    },
    {
        name: 'RARS: Real-Time Agricultural Response System',
        summary: "This project involved a team effort (4 members) to leverage a real-time operating system to automate the management activities of a simulated greenhouse. I wrote a report to present the project's rationale, technical details, design and performance.",
        context: Context.CLASS,
        skills: new Set(['QNX Neutrino', 'C', 'R']),
        link: 'https://github.com/atmask/RARS-COMP4900',
        photo: 'greenhouse.svg'
    },
    {
        name: 'GPA Calculator',
        summary: "This project enabled students to track their GPA throughout the semester, helping them calculate the grades needed to reach a target final grade. This project was a feature of an Android app for Carleton University students.",
        context: Context.VOLUNTEER,
        company: "Science Student Success Centre",
        skills: new Set(['Java', 'XML', 'Git', 'GitHub']),
        photo: 'mobile.svg',
        link: 'https://github.com/ScienceStudentSuccessCentre/SSSC-Android-app'
    },
    {
        name: 'CV Compiler',
        summary: "CV compiler is a program to speed up editing tasks for an academic CV. It combines BibTex (for publications) and YAML (for work experiences) data into a single PDF file. This project will offer mechanisms to filter the information to meet the requirements of potential employers and grants.",
        context: Context.WORK,
        company: "Carleton's Human-Oriented Research in Usable Security Lab",
        skills: new Set(['YAML', 'LaTeX', 'Python']),
        photo: 'flowchart.svg',
        link: 'https://github.com/ElisaKazan/cv-chiasson'
    },
    {
        name: "Bookstore Database",
        summary: "This project involved translating data requirements into a relational database design using an Entity Relationship (ER) diagram. Additionally, I implemented the database using the DDL SQL language for PostgreSQL. I plan on building a full-stack application based on this database to track my book collection.",
        context: Context.CLASS,
        skills: new Set(['PostgreSQL']),
        photo: 'database.svg'
    }
]

export const projectHighlights : IProject[] = [
    {
        name: 'Personal Website',
        summary: "My little corner of the internet.",
        context: Context.PERSONAL,
        skills: new Set(['AWS', 'TypeScript', 'React', 'SASS', 'GitHub Actions']),
        photo: 'cloud.svg',
        link: 'https://www.sebastiannavas.ca'
    },
    {
        name: 'Password Constellation',
        summary: "A tool to visualize relationships between your passwords.",
        context: Context.PERSONAL,
        skills: new Set(['TypeScript', 'React', 'D3', 'Graphs', 'Louvain Cluster', 'SASS', 'LaTeX']),
        photo: 'password-constellation.png',
        link: 'https://report-hub.scs.carleton.ca/projectPage/1481/'
    },
    {
        name: 'NoViz: Non-Visual Security Notifications (Thermal)',
        summary: "A heat cue generator for security notifications.",
        context: Context.WORK,
        company: "Carleton's Human-Oriented Research in Usable Security Lab",
        skills: new Set(['Arduino', 'JavaScript', 'MongoDB', 'Node', 'Express', 'LaTeX', 'Markdown']),
        link: 'https://github.com/choruslab/NoVizThermal',
        photo: 'noviz-t.jpg'
    },
    {
        name: 'NoViz: Non-Visual Security Notifications (Smell)',
        summary: "A smell cue generator for security notifications.",
        context: Context.WORK,
        company: "Carleton's Human-Oriented Research in Usable Security Lab",
        skills: new Set(['Arduino', 'Node', 'Express', 'REST', 'JavaScript', 'LaTeX']),
        link: 'https://github.com/choruslab/NoVizSmell',
        photo: 'noviz-s.png'
    },
]

export const ProjectContextOptions: readonly ProjectContextOption[] = [
    { value: Context.CLASS, label: 'Class' },
    { value: Context.PERSONAL, label: 'Personal' },
    { value: Context.RESEARCH, label: 'Research' },
    { value: Context.VOLUNTEER, label: 'Volunteer' },
    { value: Context.WORK, label: 'Work' },
];
