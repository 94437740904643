import React, { useState } from 'react';
import Select from 'react-select';
import chroma from 'chroma-js';
import Card from '../utils/components/Card';
import CardContainer from '../utils/components/CardContainer';
import { projectData, ProjectContextOptions } from '../utils/data/Projects';
import { Context, IProject } from '../utils/types/Projects';
import { SkillOptions } from '../utils/data/Skills';
import "../utils/styles/Projects.scss";

function Projects() : React.ReactElement {
  const [ context, setContext ] = useState<Set<Context>>(new Set());
  const [ skills, setSkills ] = useState<Array<String>>([]);

  const skillFilterStyles = () => {
    return {
      multiValue: (styles : any, data: any) => {
        const color = chroma(data.data.colour);
        return {
          ...styles,
          backgroundColor: color.alpha(0.1).css(),
        };
      },
      multiValueLabel: (styles : any, data: any) => {
        const color = chroma(data.data.colour);
        return {
          ...styles,
          color: color.css()
        }
      },
      multiValueRemove: (styles : any, data: any) => {
        const color = chroma(data.data.colour);
        return {
          ...styles,
          color: color.css(),
          ':hover': {
            backgroundColor: color.css(),
            color: 'white',
          }
        }
      }
    }
  }

  return (
    <div className = "content">

      <div id="filter">
        <div id="title">Filters:</div>
        <Select
          // defaultValue={ProjectContextOptions[4]}
          isMulti
          name="context"
          options={ProjectContextOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="By Context..."
          onChange={(choice) => {setContext(new Set(choice.map(element => element.value)))}}
        />
        <Select
          isMulti
          styles={skillFilterStyles()}
          name="skills"
          options={SkillOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="By Skill..."
          onChange={(choice) => {setSkills(choice.map(element => element.value))}}
        />
      </div>

      <CardContainer>
        {projectData
          .filter( (element: IProject) => 
            (context.size > 0 ?
              context.has(element.context)
              :
              true
            ) 
              && 
            (
              skills.length > 0 ? 
                skills.filter((skill : String) => element.skills.has(skill)).length > 0 
                : 
                true
            )
          )
          .map( (element, index) => (
          <Card key={index}
                name={element.name} 
                summary={element.summary} 
                company={element.company} 
                context={element.context}
                skills={element.skills}
                photo={element.photo}
                link={element.link}/>
        ))}
      </CardContainer>
      
    </div>
  );
}

export default Projects;