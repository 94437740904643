/**
 * An action statement is a SAR statement, a resume-style phrase.
 */
type ActionStatement = string;

/**
 * This interface represents the data types for work experience and portfolio items
 * Note: title may be a project name or job title
 * Note: description can be a paragraph description or a list of a SAR statements
 */
export interface IExperience {
    title : string,
    timeline? : string,
    company? : string
    description: string | ActionStatement[],
    skills: string[]
}

export const titles : Array<string> = ["Software Developer", "Cloud Enthusiast", "Researcher"];