import React from 'react';
import "../styles/CardContainer.scss";

function CardContainer({children} : any) : React.ReactElement {

    return (
        <div className="cardContainer">
            {children}
        </div>
    );
  }
  
  export default CardContainer;