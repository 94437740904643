/**
 * Represents a Project, including personal, work and future projects. 
 */
export interface IProject {
    name : String,
    duration?: Number,
    context: Context,
    summary: String,
    company?: String,
    skills: Set<String>,
    photo?: string
    link?: string
}

export enum Context {
    WORK = 'Work',
    VOLUNTEER = 'Volunteer',
    RESEARCH = 'Research',
    PERSONAL = 'Personal',
    CLASS = 'Class'
}

export interface ProjectContextOption {
    readonly value: Context;
    readonly label: string;
}