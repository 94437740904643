import { IProject } from "../types/Projects";
import { SkillOption, ISkillCategory } from "../types/Skills";
import { projectData } from "./Projects";


export const skillCategories : ISkillCategory[] = [
    {
        name: 'Development Tools',
        skills: ["Arduino", "Markdown", "LaTeX", "HTML",  "Express", "React", "React Router", 
                 "D3", "JavaFX", "SpecFlow", "Jest", "REST", "GraphQL", "Git", "GitHub", "Agile"],
        colour: '#af580c'
    },
    {
        name: 'Data',
        skills: ["MongoDB", "PostgreSQL", "XML", "YAML", "MySQL", "DB2", "Louvain Cluster", "Fisher-Yates Shuffle", "Graphs", "Microsoft Excel", "Qualtrics", "Data Visualizations"],
        colour: '#8b2295'
    },
    {
        name: 'Programming Languages',
        skills: ["C#", "Python", "TypeScript", "Java", "JavaScript", "C++", "C", "R", "SASS"],
        colour: '#085d8a'
    },
    {
        name: 'Research',
        skills: ["Interviews", "Usability Testing", "Writing", "Presenting"],
        colour: '#1e6133'
    },
    {
        name: 'Infrastructure',
        skills: ["Ubuntu Linux", "QNX Neutrino", "OpenStack", "Google Cloud", "AWS", "Apache", "WordPress", "GitHub Actions", ".NET", "QT", "Node"],
        colour: '#b2375e'
    }
]


/**
 * Transform list of ISKillCategory into <skill, colour> pairs
 * @returns Map of <skill, colour> pairs to find colours faster for each skill
 */
function create_skill_colour_list() : Map<String, string> {
    let colour_list = new Map<String, string>();

    skillCategories.forEach( (category : ISkillCategory ) => {
        category.skills.forEach( (skill : String) => {
            colour_list.set(skill, category.colour);
        });
    });

    return colour_list;
}


export const skill_colours : Map<String, string> = create_skill_colour_list();


/**
 * Create a set of skills (no duplicates)
 */
function unique_skills() : Set<String> {
    let grouped_skills = new Set<String>();

    projectData.forEach( (element : IProject) => {
        Array.from(element.skills).forEach(e => grouped_skills.add(e));
    });
    
    return grouped_skills;
}


/**
 * Parse skills into a format that react-select can understand
 */
function create_skill_options() : readonly SkillOption[] {
    const skills : Array<String> = Array.from(unique_skills());

    return skills.map( e => {return {'value' : e, 'label': e, 'colour' : (skill_colours.get(e) || 'black')}});

}


export const SkillOptions : readonly SkillOption[] = create_skill_options();